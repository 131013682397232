/* You can add global styles to this file, and also import other style files */
@import "./@fury/styles/core";
@import "./app/app.theme";
//@import '~@angular/cdk/overlay-prebuilt.css';
@import "~quill/dist/quill.snow.css";
@import "~quill/dist/quill.snow.css";


.mat-form-field-label {
  color: rgba(0, 0, 0, 0.8) !important;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
