.mat-button,
.mat-raised-button {
  border-radius: 0;

  &.block {
    width: 100%;
  }

  .mat-icon {
    margin-right: 8px;
  }
}

.mat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-badge-content.mat-primary {
  background-color: #1b143c;
  color: #fff;
}

.mat-button.mat-accent,
.mat-raised-button.mat-accent,
.mat-badge-content.mat-accent {
  background-color: #DD2815;
  color: #ffffff;
}

.mat-button.mat-default,
.mat-raised-button.mat-default,
.mat-badge-content.mat-primary {
  background-color: #fff;
  border: 2px solid #1b143c;
  color: #1b143c;
  line-height: 32px;
}

.mat-button.mat-warn,
.mat-raised-button.mat-warn,
.mat-badge-content.mat-warn, {
  background-color: #FFA500;
  color: #333333;
}

.mat-button.mat-black,
.mat-raised-button.mat-black,
.mat-badge-content.mat-black, {
  background-color: #333;
  color: #fff;
}

.mat-button.mat-primary[disabled],
.mat-raised-button.mat-primary[disabled],
.mat-button.mat-secondary[disabled],
.mat-raised-button.mat-secondary[disabled],
.mat-button.mat-accent[disabled],
.mat-raized-button.mat-accent[disabled],
.mat-button.mat-default[disabled],
.mat-raised-button.mat-default[disabled],
.mat-button.mat-warn[disabled],
.mat-raised-button.mat-warn[disabled],
.mat-icon-button[disabled] {
  opacity: 0.6;
  box-shadow: none;
}

.mat-checkbox-indeterminate.mat-dark-grey .mat-checkbox-background,
.mat-checkbox-checked.mat-dark-grey .mat-checkbox-background {
  background: #555555;
}

.mat-toolbar {
  background: #1b143c;
  color: #fff;

  & > .left-buttons:not(:empty) {
    margin-left: -10px;
  }
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 54px;
  min-height: 54px;
  padding: 0 10px;
}

.mat-bottom-sheet-container {
  padding: 8px 10px;

  .header {
    margin-bottom: 5px;

    h2 {
      margin: 0;
      font-size: 18px;
      font-weight: bold;
      line-height: 20px;
    }

    .close {
      width: 32px;
      height: 32px;

      .mat-icon {
        background: #000;
        color: #fff;
        padding: 4px;
        vertical-align: top;
      }
    }
  }
}

.mat-bottom-sheet-container-medium {
  width: 100vw;
  min-width: 100vw;
  max-width: initial;
  border-radius: 0;
  padding: 16px;
}

.mat-list-base {
  padding: 0;

  h3 {
    font-size: 16px;
    line-height: 16px;
    margin: 0 0 4px 0;
  }

  .mat-list-item {

    .mat-list-item-content {
      padding: 12px 10px !important;

      & > div {
        min-width: 0;

        & > div {
          min-width: 0;
        }
      }
    }

    .mat-line {
      line-height: 18px;

      & > div {
        white-space: nowrap;
        text-overflow: ellipsis;;
        overflow: hidden;
      }
    }
  }
}
